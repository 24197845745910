import React from 'react';
import NavBar from './components/NavBar'; 
import ChatBot from './components/ChatBot'; 
import Body from './components/Body'; 
import './App.css';

function App() {
  return (
    <div className="App">
      <NavBar />
      <ChatBot />
      <Body/>
    </div>
  );
}

export default App;
