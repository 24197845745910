import React, { useState } from 'react';
import './NavBar.css';

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-logo">BlueGrids</div>
      <ul className={`navbar-links ${isOpen ? 'open' : ''}`}>
        <li><a href="#home" onClick={() => setIsOpen(false)}>Home</a></li>
        <li><a href="#about" onClick={() => setIsOpen(false)}>About</a></li>
        <li className="ai-chatbot-item">
          <a href="#aibot" onClick={() => setIsOpen(false)}>
            <span className="free-label">Free</span>
            AI Chatbot
          </a>
        </li>
        <li><a href="#contact" onClick={() => setIsOpen(false)}>Contact</a></li>
      </ul>
      <div className="navbar-toggle" onClick={toggleMenu}>
        <span className={`bar ${isOpen ? 'rotate' : ''}`}></span>
        <span className={`bar ${isOpen ? 'rotate' : ''}`}></span>
        <span className={`bar ${isOpen ? 'rotate' : ''}`}></span>
      </div>
    </nav>
  );
};

export default NavBar;
