import React from 'react';
import './Body.css';
import { FaCopy } from 'react-icons/fa';

const Body = () => {
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    alert('Script copied to clipboard!');
  };

  return (
    <div className="body-container">
      <section id="home" className="hero-section">
        <div className="hero-content">
          <h1>AI Chatbot for your business</h1>
          <p>
            Transform your customer interactions with our AI-driven chatbot solutions. Seamlessly integrate with just one line of code for instant, intelligent conversations.
          </p>
        </div>
        <div className="hero-video">
          <div className="video-placeholder">
            {/* Placeholder for video */}
            <p>Video Placeholder</p>
          </div>
        </div>
      </section>

      <section id="about" className="about-section">
        <div className="about-content">
          <div className="about-text">
            <h2>About Us</h2>
            <p>
              At <strong>BlueGrids</strong>, we are passionate about harnessing the power of technology to create exceptional digital experiences. Founded with a vision to revolutionize the way businesses interact with their customers, we specialize in delivering cutting-edge solutions that seamlessly integrate AI-driven chatbots into your operations.
            </p>
            <p>
              Our team of dedicated professionals combines years of expertise in technology and design to bring you innovative, user-centric solutions. We believe in the transformative power of AI and are committed to helping our clients leverage this technology to enhance their customer engagement, streamline operations, and drive growth.
            </p>
            <p>
              Our core values are <strong>innovation</strong>, <strong>integrity</strong>, and <strong>customer satisfaction</strong>. We strive to push the boundaries of what’s possible, ensuring that every project we undertake meets the highest standards of quality and excellence. With a focus on continuous improvement and adaptation, we are here to support you every step of the way in achieving your business goals.
            </p>
            <p>
              Join us on our journey to shape the future of digital interactions and discover how BlueGrids can empower your business with smart, effective, and engaging solutions.
            </p>
          </div>
        </div>
      </section>

      <section id="aibot" className="services-section">
        <h2>AI ChatBot</h2>
        <div className="services-list">
          <div className="service-item">
            <h3>Travel Bot</h3>
            <p>Get travel itineraries effortlessly.</p>
            <div className="copy-button" onClick={() => copyToClipboard('Integration Script Here')}>
              <FaCopy />
            </div>
          </div>
          <div className="service-item">
            <h3>Customer Care Bot</h3>
            <p>Resolve user issues efficiently.</p>
            <div className="copy-button" onClick={() => copyToClipboard('Custom AI Script Here')}>
              <FaCopy />
            </div>
          </div>
          <div className="service-item">
            <h3>Feedback Bot</h3>
            <p>Collect user feedback seamlessly.</p>
            <div className="copy-button" onClick={() => copyToClipboard('Analytics Script Here')}>
              <FaCopy />
            </div>
          </div>
        </div>
      </section>

      <section id="contact" className="body-section">
        <h2>Contact Us</h2>
        <p>
          Have any questions? We'd love to hear from you. Reach out to us at <a href="mailto:info@bluegrids.in">info@bluegrids.in</a>.
        </p>
      </section>
    </div>
  );
};

export default Body;
